@font-face {
  font-family: ProximaNova;
  src: url("assets/fonts/proxima_nova.eot");
  src: url("assets/fonts/proxima_nova.eot?#iefix") format("embedded-opentype"),url("assets/fonts/proxima_nova.woff2") format("woff2"),url("assets/fonts/proxima_nova.woff") format("woff"),url("assets/fonts/proxima_nova.ttf") format("truetype"),url("assets/fonts/proxima_nova.svg#wf") format("svg");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: ChampagneLimousines;
  src: url("assets/fonts/champagne_limousines.ttf");
  font-weight: 400;
  font-style: normal
}

.AppBackground {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("assets/images/l-oiseau.jpg") no-repeat center center scroll; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  margin: 0;
}

.App {
  font-family: ProximaNova, sans-serif;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 501px) {
  .VisuallyHidden {
    position: absolute;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}

.BackgroundImage,
.BackgroundImageMobile {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.BackgroundImage {
  display: block;
}
.BackgroundImageMobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .BackgroundImage {
    display: none;
  }
  .BackgroundImageMobile {
    display: block;
    max-width: 100%;
  }
}

h1 {
  font-family: ProximaNova, serif;
  font-size: 1.3em;
  letter-spacing: 2px;
  margin-left: 15px;
  margin-top: 180px;
  position: absolute;
  text-transform: uppercase;
  z-index: 999;
}

h2 {
  font-family: ChampagneLimousines, serif;
  font-size: 2em;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

h3 {
  font-family: ChampagneLimousines, serif;
  font-size: 1.5em;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h4 {
  font-weight: normal;
  margin-bottom: 0;
}

@media screen and (max-width: 800px) {
  h1 {
    margin-left: 50%;
    margin-top: 10%;
    -ms-transform: rotate(55deg);
    -webkit-transform: rotate(55deg);
    transform: rotate(55deg);
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 1em;
    margin-left: 15px;
    margin-top: 15px;
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1em;
  }
  .BackgroundImageMobile {
    max-width: 500px;
  }
}

.Content {
  font-family: ProximaNova;
  margin-left: 4%;
  position: absolute;
  text-align: center;
  margin-top: 450px;
  width: 350px;
  z-index: 999;
}

@media screen and (max-width: 800px) {
  .Content {
    margin-top: 100px;
    margin-left: 15px;
    max-width: 100vh;
    text-align: left;
    font-size: 1em;
  }
}

@media screen and (max-width: 500px) {
  .Content {
    margin-top: 75px;
  }
}

.Footer span {
  display: inline-block;
  padding: 2px 16px;
}

.ContactButton,
.AboutButton {
  background: none;
  border: 0;
  cursor: pointer;
  font-family: ProximaNova;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.ContactButton:hover,
.AboutButton:hover {
  background: none;
  display: inline-block;
  text-decoration: underline;
}

.Instagram {
  display: inline-block;
}

.Instagram img {
  display: inline-block;
  vertical-align: top;
}

@media screen and (max-width: 800px) {
  .Footer span {
    display: none;
  }
  .ContactButton,
  .AboutButton {
    display: block;
    margin-bottom: 15px;
  }
}

.AboutPortrait {
  height: auto;
  width: 100%;
}

.ReactModalPortal {
  font-family: ProximaNova, sans-serif;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  bottom: auto !important;
  box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0.2);
  left: calc(50% - 220px) !important;
  max-height: calc(100% - 125px);
  max-width: 400px;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 150ms ease-in-out;
}

.ReactModal__Content h4 {
  font-weight: bold;
}

@media screen and (max-width: 520px) {
  .ReactModal__Content {
    left: 40px !important;
  }
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(-50px);
}

.ModalContent-Header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.ModalContent-Header button {
  background: rgba(0, 0, 0, 0.1);
  border: 0; 
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  line-height: 22px;
  height: 25px;
  text-align: center;
  width: 25px;
}

.ReactModal__Content form .FormElement {
  margin: 20px 0;
  text-align: center;
}

.ReactModal__Content form .FormElement:last-child {
  margin-bottom: 0;
}

.FormElement input,
.FormElement textarea {
  font-family: ProximaNova, serif;
  font-size: 16px;
  height: 40px;
  line-height: 30px;
  padding: 0 10px;
  width: calc(100% - 24px);
}

.FormElement textarea {
  height: 80px;
  line-height: 20px;
  padding-top: 8px;
}

.FormElement button {
  background:rgba(0, 0, 0, 0.8);
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
}

.FormElement--Message {
  font-size: 0.8em;
}